/*** Header ***/
.carousel-caption {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  z-index: 1;
}

.carousel-control-prev,
.carousel-control-next {
  width: 10%;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  width: 3rem;
  height: 3rem;
}

@media (max-width: 375px) {
  #header-carousel .carousel-item {
    position: relative;
    min-height: 450px;
  }

  #header-carousel .carousel-item img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.page-header {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.page-header-inner {
  background: rgba(0, 0, 0, 0.7);
}

.breadcrumb-item + .breadcrumb-item::before {
  color: var(--light);
}
